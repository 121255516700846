export default {
    title: 'Add to Cart Rate',
    location: '/business/add-to-cart-rate',
    tabs: [
        {
            name: 'By Country',
            link: `/business/add-to-cart-rate`
        },
        {
            name: 'Trend',
            link: `/business/add-to-cart-rate/:market`
        }
    ]
}
